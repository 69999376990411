import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './Admin.scss';

class Admin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			streamer: [],
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.id]: e.target.value });
		console.log(this.state);
	}

	handleSubmit(event) {
		console.log('A name was submitted: ' + this);
		event.preventDefault();
	}

	componentDidMount() {
		axios
			.get('https://www.lycosongaming.de/api/streampartner/')
			.then(({ data }) => {
				this.setState({ streamer: data });
			});
	}

	render() {
		return (
			<div className="Admin">
				<div className="col-lg-12">
					{!this.props.history.location.query ? (
						<div className="col-lg-12">
							<Link to="/addNewStreamer">
								Stremer Hinzufuegen
							</Link>
						</div>
					) : null}
					<div className="Admin-wrapper">
						{!this.props.history.location.query ? (
							this.state.streamer.map((streamer) => {
								return (
									<div key={streamer.streampartner_ID}>
										<Link
											to={{
												pathname: `/admin`,
												query: {
													streamerId:
														streamer.streampartner_ID,
													streamerGamer:
														streamer.streampartner_Gamer,
													streamerUrl:
														streamer.streampartner_Url,
													streamerImg:
														streamer.streampartner_Img,
													streamerDescription:
														streamer.streampartner_Description,
												},
											}}
										>
											<img
												src={streamer.streampartner_Url}
												alt={
													streamer.streampartner_Gamer
												}
											/>
										</Link>
									</div>
								);
							})
						) : (
							<div className="col-lg-12">
								<div className="Admin-streamer">
									<img
										src={
											this.props.history.location.query
												.streamerImg
										}
										alt={
											this.props.history.location.query
												.streamerGamer
										}
									/>
								</div>
								<form
									onSubmit={this.handleSubmit}
									className="Admin-form"
								>
									<label value="Streamer">Streamer</label>
									<input
										id="streamerGamer"
										type="text"
										onChange={this.onChange}
										value={
											this.props.history.location.query
												.streamerGamer
										}
									/>
									<label value="Kanal URL">Kanal URL</label>
									<input
										id="streamerUrl"
										type="text"
										onChange={this.onChange}
										value={
											this.props.history.location.query
												.streamerUrl
										}
									/>
									<label value="Kanal Bild">Kanal Bild</label>
									<input
										id="streamerImg"
										type="text"
										onChange={this.onChange}
										value={
											this.props.history.location.query
												.streamerImg
										}
									/>
									<label value="Kanal Beschreibung">
										Kanal Beschreibung
									</label>
									<textarea
										id="streamerDescription"
										type="text"
										onChange={this.onChange}
										value={
											this.props.history.location.query
												.streamerDescription
										}
									/>
									<button>Speichern</button>
									<Link to="/admin">Abbrechen</Link>
								</form>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Admin;
