import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './AddNewStreamer.scss';

class AddNewStreamer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			streamer: [],
		};
	}

	componentDidMount() {
		axios
			.get('https://www.lycosongaming.de/api/streamPartner/')
			.then(({ data }) => {
				this.setState({ streamer: data });
			});
	}

	render() {
		return (
			<div className="AddNewStreamer">
				<div className="col-lg-12">
					<form onSubmit={this.handleSubmit} className="Admin-form">
						<label value="Streamer">Streamer</label>
						<input type="text" placeholder="Streamer" />
						<label value="Kanal URL">Kanal URL</label>
						<input type="text" placeholder="Kanal Url" />
						<label value="Kanal Bild">Kanal Bild</label>
						<input type="text" placeholder="Kanal Bild" />
						<label value="Kanal Beschreibung">
							Kanal Beschreibung
						</label>
						<textarea
							type="text"
							placeholder="Kanal Beschreibung"
						/>
						<button>Speichern</button>
						<Link to="/admin">Abbrechen</Link>
					</form>
				</div>
			</div>
		);
	}
}

export default AddNewStreamer;
